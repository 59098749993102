<template>
    <div class="all-options">

        <Item
            category="Default Options"
            :items="defaultOptions"
            v-if="defaultOptions.length"
            @selected="$emit('jumpTo', $event)"
        />
        <Item
            category="Available Options"
            :items="availableOptions"
            v-if="availableOptions.length"
            @selected="$emit('jumpTo', $event)"
        />

    <AllOptionDescription :isQuoting="isQuoting"/>

    </div>
</template>

<script>
import Item from './options/all-options/Item.vue'
import AllOptionDescription from "@/components/item-wizard/components/AllOptionDescription";
import { ref } from 'vue'
export default {
    props: ['items','isQuoting'],
    components: {
        Item,
        AllOptionDescription
    },
    setup(props) {
        const defaultOptions = ref([])
        const availableOptions = ref([])

        getData()

        function getData() {
            props.items.steps.forEach(el => {
                // if(el.all_options && el.visible) {
                    if(el.required) defaultOptions.value.push(el)
                    else availableOptions.value.push(el)
                // }
            })
            defaultOptions.value = defaultOptions.value.filter(item => item.visible && item.all_options)
            availableOptions.value = availableOptions.value.filter(item => item.visible && item.all_options)
        }

        return {
            defaultOptions,
            availableOptions
        }
    }
}
</script>
