<template>
    <div class="category">
        <div class="category__title">{{ category }}</div>
        <div class="category__items">
            <div class="category__item item" v-for="(item, index) in items"  :class="'category__item--visible'" :key="`${category.toLowerCase()}-item-${index}`" @click="$emit('selected', item.id)">
                <div class="item__icon-wrapper">
                    <img class="item__img" :src="item.data.image_url" alt="" v-if="item.data && item.data.image_url">
                    <svg-icon-qouting class="item__icon" color="var(--SecondaryColour)" :data="item.icon" v-else/>
                </div>
                <div class="item__title">{{ getTitle(item) }}
                  <div class="item__title" v-if="item.name == 'Frame Colour' || item.name == 'Insert Colour'">{{item.data.name}}
                    <p v-if="item.data.is_same_as_frame">(same as frame)</p>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default({
    props: ['category', 'items'],
    setup() {
        function getTitle(item) {
            if(item.name.toLowerCase() == 'option') return item.option
            return item.all_options_name || item.name
        }

        return {
            getTitle
        }
    }
})
</script>


<style scoped lang="scss">
.category {
    margin-bottom: 30px;
    &__title {
        font-size: 15px;
        line-height: 20px;
        text-transform: uppercase;
        color: #000000;
        text-align: left;
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        margin-right: 40px;
        margin-top: 20px;
        text-align: center;
        cursor: pointer;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        align-self: flex-start;
      &--visible {
        display: flex;
      }
    }
    @media(max-width: 768px) {
        &__items {
            justify-content: center;
        }
        &__item {
            margin: 20px 10px 0;
        }
    }
}
.item {
    &__title {
        font-size: 15px;
        margin-top: 5px;
    }
    &__icon {
        font-size: 120px;
        display: flex;
        &-wrapper {
          height: 100%;
          display: flex;
          align-items: center;
          align-self: center;
        }
        @media(max-width: 768px) {
            font-size: 90px;
        }
    }
    &__img {
        border: 2px solid #000;
    }
}
</style>
