<template>
  <div class="description">
    <div class="description__title" :class="{'bold' :!isQuoting}"> Save your Product</div>
    If you are finished editing this screen, you can:

    <ul :class="{'margin_bottom' :!isQuoting}">
      <li> <b :class="{'bold' :!isQuoting}">Save</b> – Save is like Add to Cart. Choose this option if you are finished with this estimate for now.</li>
      <li><b :class="{'bold' :!isQuoting}">Save & Create New </b>- Add to Cart and Choose this option if you would like to save this screen and add a new item to your estimate.</li>
      <li><b :class="{'bold' :!isQuoting}">Save and Clone</b> - Choose this option if you would like to save this screen and add a new screen to your estimate with the same specifications to the same location.</li>
    </ul>
  </div>
</template>

<script>
export default {
 props: ['isQuoting']
}
</script>


<style scoped lang="scss">
.description {
  margin-bottom: 30px;
  text-align: left;
  &__title {
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: left;

  }
}
ul {
  margin-top: 10px;
}
ul li {
  display: inline-block;
  list-style-type: disc;
  list-style-position: inside;
  padding: 0 0 5px 15px;
  text-indent: -1em;
  font-weight: normal;
}

ul li:before {
  content: "• ";
  font-size:16px;
}
.margin_bottom{
  margin-bottom: 60px;
}
.bold{
  font-weight: bold;
  color: #000000;
}

</style>

